<template>
  <v-container fluid class="mb-12">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-row class="pt-6 pb-3 d-flex justify-space-between">
          <v-col
            class="align-center justify-start d-flex flex-shrink"
            cols="4">
            <v-sheet
              height="100%"
              color="#EDEFF1"
              v-if="movementSelecteds.length > 0">
              <v-slide-x-reverse-transition>
                <v-container
                  fluid>
                  <v-row
                    no-gutters
                    style="height: 100%">
                    <div>
                      <span
                        class="pr-4"
                        style="color: #3BA3E3;">
                        {{ allMovementsSelected.length }}
                        {{ allMovementsSelected.length > 1 ? 'Registros Selecionados' : 'Registro Selecionado' }}
                      </span>
                    </div>
                    <div
                      v-if="!allPossibleMovementsSelected"
                      class="d-flex align-center justify-center pr-4">
                      <v-icon
                        size="20"
                        color="grey">
                        fas fa-arrow-right
                      </v-icon>
                    </div>
                    <div
                      v-if="!allPossibleMovementsSelected"
                      class="d-flex align-center justify-space-between">
                      <v-slide-x-transition>
                        <div
                          v-if="!loadingMovementIds"
                          class="d-flex flex-row flex-grow-1 justify-space-between">
                          <span
                            class="text-decoration-underline"
                            style="cursor: pointer;"
                            @click="selectAllPossibleMovements">
                            Selecionar Todos ({{allMovementIds.length}})
                          </span>
                        </div>
                      </v-slide-x-transition>
                    </div>
                    <div>
                      <v-icon
                        size="25"
                        color="grey"
                        @click="cancelSelection">
                        close
                      </v-icon>
                    </div>
                  </v-row>
                </v-container>
              </v-slide-x-reverse-transition>
            </v-sheet>
            <v-progress-circular
              v-if="loadingMovementIds"
              class="ml-2"
              indeterminate
              color="primary"
            />
          </v-col>
          <v-col align="end" class="mr-5" cols="7">
            <v-btn color="textPrimary" class="mr-5" :disabled="loadingMovements || (movements && movements.length === 0)" outlined @click="getMovements()">
              <v-icon class="mr-2">mdi-refresh</v-icon>
              Atualizar
            </v-btn>
            <template v-if="hasAuthorityViewAllMovements" >
              <v-btn class="mr-5" :disabled="loadingMovements || (movements && movements.length === 0)" color="#3B495B" width="150px" outlined @click="openFilterExportMovementModal()">
                <v-icon left dark>fas fa-download</v-icon>
                Exportar
              </v-btn>
            </template>
            <template v-if="hasAuthorityViewWalletMovementAction || hasAuthorityReleaseMovement" >
            <v-menu open-on-click bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="white--text" :disabled="loadingMovements || (movements && movements.length === 0)" color="primary" v-bind="attrs" v-on="on">
                  <v-icon left color="white">fas fa-cog</v-icon>
                  <span class="pr-2">Ações em bloco | </span>
                  <v-icon class="pr-0 mr-0" left color="white">fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <div v-if="this.hasAuthorityAdvancedMovement && !this.isRHProtegido && !allPossibleMovementsSelected">
                  <v-list-item @click="onClickFillCardFinish()">
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon color="primary">
                        fa-address-card
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="mt-1">
                      <v-list-item-title>Finalizar movimentações</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div v-if="hasAuthorityViewWalletMovementAction && !hasAuthorityAdvancedMovement && !allPossibleMovementsSelected">
                  <v-list-item @click="onClickFillCard()">
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon color="primary">
                        fa-address-card
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="mt-1">
                      <v-list-item-title>Finalizar movimentações</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div v-if="hasAuthorityReleaseMovement && !allPossibleMovementsSelected">
                  <v-list-item @click="onClickReleaseMovement()">
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon color="primary">
                        fas fa-clipboard-check
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="mt-1">
                      <v-list-item-title>Liberar movimentações</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="onClickRevertRelease()">
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon color="primary">
                        fas fa-undo
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="mt-1">
                      <v-list-item-title>Reverter liberações</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <v-list-item
                  @click="onClickCancelMovementBlock()">
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon color="primary">
                        fas fa-trash
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="mt-1">
                      <v-list-item-title>Cancelar movimentações</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
              </v-list>
            </v-menu>
          </template>
          </v-col>
        </v-row>
        <v-data-table
          class="elevation-1 fixed-header"
          item-key="id"
          show-expand
          fixed-header
          :headers="headers"
          :items="movements"
          :loading="loadingMovements"
          :expanded.sync="expandedDependents"
          :server-items-length="totalPages"
          @item-expanded="loadDependents"
          :options.sync="options"
          :items-per-page="itemsPerPage"
          :page="page"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 75, 100],
            disablePagination: loadingMovements,
          }"
          :height="movements && movements.length >= 10 ? 528 : null"
          @update:options="changePage($event)"
          hide-default-header
        >
          <template v-slot:[`header`]="{ props }">
            <thead>
              <tr>
                <th style="background-color: #d9d9d9 !important; text-align: start;" v-for="(header, index) in props.headers" :key="index">
                  <v-simple-checkbox
                    v-if="index === 0"
                    color="textPrimary"
                    v-model="isMultSelect"
                    :disabled="loadingDependents"
                    @click="selectAllMovements()"
                  />
                  <label class="label primary--text">
                    {{ header.text }}
                  </label>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand, index }">
            <v-icon
              v-if="!loadingDependents && item.dependentsSize > 0"
              :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
              @click.stop="expand(!isExpanded)">
              $expand
            </v-icon>
            <v-progress-circular
              v-if="loadingDependents && indexInLoading === index"
              indeterminate
            />
          </template>
          <template v-slot:[`item.fileDataId`]="{ item }">
            {{ item.fileDataId ? item.fileDataId : '-' }}
          </template>
          <template v-slot:[`item.financialGroupId`]="{ item }">
            <span style="white-space: nowrap;">
              {{ item.financialGroupId ? formatter.formatFinancialGroup(item.financialGroupId, financialGroups) : '-' }}
            </span> <br>
            <span style="white-space: nowrap;">
              {{ item.insuranceCarrier ? item.insuranceCarrier : '-' }}
            </span>
          </template>
          <template v-slot:[`item.policy`]="{ item }">
            <span>
              {{ benefitData(item) }}
            </span>
          </template>
          <template v-slot:[`item.movementType`]="{ item }">
            <v-row class="pl-3">
              <div class="mr-2">
                <!-- <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="textPrimary" dark v-bind="attrs" v-on="on">
                      fas fa-info-circle
                    </v-icon>
                  </template>
                  <div class="custom-tooltip">
                    {{ `Criada em: ${formatDateAndTime(item.movementDate).date}` }}<br>
                    {{ `às: ${formatDateAndTime(item.movementDate).time}` }}<br>
                    {{ `Por: ${item.userName ? item.userName : 'Não informado'}` }}
                    <div class="v-tooltip-arrow"></div>
                  </div>
                </v-tooltip> -->
                <v-icon
                  color="textPrimary"
                  dark
                  @click="openMovementDetailsModal(item)">
                  fas fa-info-circle
                </v-icon>
              </div>
              <div>
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="textPrimary" dark v-bind="attrs" v-on="on">
                      {{ item.movementType ? formatter.movementTypeIcon(item.movementType) : '-' }}
                    </v-icon>
                  </template>
                  <div class="custom-tooltip">
                    <span>{{ item.movementType ? formatter.movementTypeMessage(item.movementType, item.freeMovement) : '-' }}</span>
                  </div>
                  <div class="v-tooltip-arrow"></div>
                </v-tooltip>
              </div>
            </v-row>
          </template>
          <template v-slot:[`item.insuredName`]="{ item }">
            <div :class="item.insuredName ? 'text-decoration-underline' : ''" @click="item.insuredName ? openMovementDetailPage(item) : null" :style="hasAuthorityDetailMovement ? 'cursor: pointer; white-space: nowrap' : ''">
              {{ item.insuredName ? formatter.formatToTitleCase(item.insuredName) : '-' }} <br>
              <span>{{ item.insuredDocumentNumber ? formatter.formatCpf(item.insuredDocumentNumber) : "Sem CPF" }}</span>
            </div>
          </template>
          <template v-slot:[`item.beneficiaryType`]="{ item }">
            {{ item.beneficiaryType ? formatter.formatBeneficiary(item.beneficiaryType) : '-' }}
          </template>
          <template v-slot:[`item.dependentsSize`]="{ item }">
            {{ item.dependentsSize }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div
              class="text-center mr-3 statusMovementColumn">
              <v-tooltip
                bottom
                :disabled="!checkDependentReturnViolation(item)">
                <template v-slot:activator="{ on, attrs }">
                  <v-row
                    no-gutters
                    v-bind="attrs"
                    v-on="on"
                    class="d-flex align-center">
                    <v-sheet
                      dense
                      rounded="lg"
                      color="#F4F6F7"
                      class="d-flex align-center justify-center"
                      width="auto"
                      height="28px">
                      <label class="px-2 primary--text font-weight-medium">
                        {{ formatter.formatStatusMessage(item.status, item.beneficaryIntegrationStatus) }}
                      </label>
                    </v-sheet>
                    <v-icon
                      v-if="checkDependentReturnViolation(item)"
                      color="#D78F08"
                      size="15"
                      dark
                      class="mr-0 ml-2">
                      fa fa-exclamation-triangle
                    </v-icon>
                  </v-row>
                </template>
                <span>{{ checkDependentReturnViolationStatus(item) }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`header.critics`]="{ header }">
            <span>{{ hasAuthorityViewCriticMovement ? header.text : null }}</span>
          </template>
          <template v-slot:[`item.critics`]="{ item }" v-if="hasAuthorityViewCriticMovement">
            <v-row class="justify-center align-center">
              <v-col cols="6" class="px-0">
                <div>
                  <v-tooltip bottom
                    :disabled="
                      checkRulesViolation(item) && checkRulesStatusViolation(item)
                        ? false
                        : checkCriticismValidation(item)
                          ? false
                          : true">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn
                          color="primary"
                          icon
                          :disabled="
                            item.status !== 'INVALID'
                            ?  item.movementType === 'INSERT' && item.beneficiaryType === 'HOLDER'
                              ? insertCriticismValidation(item)
                              : checkRulesViolation(item) && checkRulesStatusViolation(item)
                                ? checkCriticismValidation(item)
                                : true
                            : checkRulesViolation(item) && checkRulesStatusViolation(item)
                              ? checkCriticismValidation(item)
                              : true
                          "
                          @click="openCriticismDialog(item, null, 'fromMovementRecord')">
                          <v-icon color="textPrimary" size="20"> fas fa-search </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>
                      {{
                        checkRulesStatusViolation(item) && checkRulesViolation(item)
                          ? 'Visualizar'
                          : checkCriticismValidation(item)
                            ? 'Verifique as críticas das demais movimentações desse grupo familiar'
                            : ''
                      }}
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="6" class="px-0">
                <v-tooltip bottom v-if="hasAuthorityViewCriticAssociationAction">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="textPrimary"
                      icon
                      class="mr-2"
                      :disabled="controlDisableManualCriticismMovement(item)"
                      v-bind="attrs"
                      v-on="on"
                      @click="openManualCriticismDialog(item)"
                    >
                      <v-icon color="textPrimary" size="20">fas fa-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Crítica Manual</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.multiselect`]="{ item }">
            <v-row class="pl-3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-checkbox
                      class="mt-5"
                      color="textPrimary"
                      v-model="movementSelecteds"
                      :value="item.id"
                      :disabled="loadingMovements"
                      @click="applyForBlockAction(item)"
                    />
                  </div>
                </template>
                <span>Selecionar</span>
              </v-tooltip>
            </v-row>
          </template>
          <template v-slot:[`item.hasError`]="{ item }">
            <div v-if="item.hasError">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn color="primary" icon @click="openMovementErrorModal(item, 'movement')">
                      <v-icon color="red" size="25"> fas fa-times </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span> Erro de Movimentação </span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-btn color="primary" icon disabled>
                <v-icon size="25"> fas fa-times </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu transition="slide-y-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="textPrimary"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="loadMovementDataByMovementRecord(item);"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <TodoListActions :actions="getActionsMovementsForItem(item)" />
            </v-menu>
          </template>
          <template v-slot:expanded-item="{ item: itemParent }">
            <td :colspan="headersDependents.length" class="mx-0 px-0">
              <v-row class="pa-0 ma-0">
                <v-col class="px-0 py-0" cols="12">
                  <v-data-table
                    loading-text="Carregando..."
                    dense
                    :headers="headersDependents"
                    :items="itemParent.dependents"
                    item-key="id"
                    hide-default-footer
                    disable-sort
                    hide-default-header
                    class="elevation-1 px-0"
                  >
                    <template v-slot:no-data>
                      <span>Este titular não possui dependente.</span>
                    </template>
                    <template v-slot:[`header`]="{ props }">
                      <thead>
                        <tr>
                          <th style="background-color: #d9d9d9 !important; text-align: start;" v-for="(header, index) in props.headers" :key="index">
                            <label class="label primary--text">
                              {{ header.text }}
                            </label>
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:[`item.insuredName`]="{ item }">
                      {{ item.insuredName ? formatter.formatToTitleCase(item.insuredName) : '-' }}
                    </template>
                    <template v-slot:[`item.insuredDocumentNumber`]="{ item }">
                      <span class="text--truncate">{{ item.insuredDocumentNumber ? formatter.formatCpf(item.insuredDocumentNumber) : "Sem CPF" }}</span>
                    </template>
                    <template v-slot:[`item.beneficiaryType`]="{ item }">
                      {{ item.beneficiaryType ? formatter.formatBeneficiary(item.beneficiaryType) : '-' }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <div
                        class="text-center statusMovementColumn">
                        <v-sheet
                          dense
                          rounded="lg"
                          color="#F4F6F7"
                          class="d-flex align-center justify-center"
                          height="23px">
                          <label class="px-2 primary--text font-weight-medium">
                            {{ formatter.formatStatusMessage(item.status, item.beneficaryIntegrationStatus) }}
                          </label>
                        </v-sheet>
                      </div>
                    </template>
                    <template v-slot:[`header.critics`]="{ header }">
                      <span>{{ hasAuthorityViewCriticMovement ? header.text : null }}</span>
                    </template>
                    <template v-if="hasAuthorityViewCriticMovement" v-slot:[`item.critics`]="{ item }">
                      <v-row class="justify-center align-center">
                        <div>
                          <v-tooltip bottom
                            :disabled="
                              checkRulesViolation(item) && checkRulesStatusViolation(item)
                                ? false
                                : checkCriticismValidation(item)
                                  ? false
                                  : true">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-btn
                                  color="primary"
                                  icon
                                  :disabled="
                                  item.status !== 'INVALID'
                                    ? item.movementType === 'INSERT' && itemParent.beneficiaryType === 'HOLDER'
                                      ? insertCriticismDependentsValidation(item, itemParent)
                                      : checkRulesViolation(item) && checkRulesStatusViolation(item)
                                        ? checkCriticismValidation(item)
                                        : true
                                    : checkRulesViolation(item) && checkRulesStatusViolation(item)
                                      ? checkCriticismValidation(item)
                                      : true
                                  "
                                  @click="openCriticismDialog(item, itemParent, 'fromMovementRecord')">
                                  <v-icon color="textPrimary" size="20"> fas fa-search </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>
                              {{
                                checkRulesStatusViolation(item) && checkRulesViolation(item)
                                  ? 'Visualizar'
                                  : checkCriticismValidation(item)
                                    ? 'Verifique as críticas das demais movimentações desse grupo familiar'
                                    : ''
                              }}
                            </span>
                          </v-tooltip>
                        </div>
                        <v-tooltip bottom v-if="hasAuthorityViewCriticAssociationAction">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="textPrimary"
                              icon
                              class="mr-2"
                              :disabled="controlDisableManualCriticismMovement(item)"
                              v-bind="attrs"
                              v-on="on"
                              @click="openManualCriticismDialog(item)"
                            >
                              <v-icon color="textPrimary" size="20">fas fa-edit</v-icon>
                            </v-btn>
                          </template>
                          <span>Crítica Manual</span>
                        </v-tooltip>
                      </v-row>
                    </template>
                    <template v-slot:[`item.hasError`]="{ item }">
                      <div v-if="item.hasError">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-btn color="primary" icon @click="openMovementErrorModal(item, 'movement')">
                                <v-icon color="red" size="25"> fas fa-times </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span> Erro de Movimentação </span>
                        </v-tooltip>
                      </div>
                      <div v-else>
                        <v-btn color="primary" icon disabled>
                          <v-icon size="25"> fas fa-times </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-menu transition="slide-y-transition" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="textPrimary"
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <TodoListActions :actions="getActionsDependentsForItem(item, itemParent)" />
                      </v-menu>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>

        <v-dialog v-model="filterModal" persistent max-width="800">
          <v-card width="800" class="modalExportMovementFiltersTopCenter pa-3 rounded-lg">
            <v-card-title class="text-h5 font-weight-bold" style="color: #3b495b;">
              <v-row
                class="d-flex justify-space-between px-3 pt-3">
                <span>Exportar Movimentações</span>
                <v-icon @click="closeFilterExportMovementModal()">
                  fas fa-times
                </v-icon>
              </v-row>
            </v-card-title>

            <v-card-text class="mt-5">
              <v-form ref="formFilterExportMovement">
                <v-row justify="start">
                  <v-col>
                    <p>Selecione o layout de relatório que deseja utilizar*</p>
                    <v-autocomplete
                      :items="getReportLayoutTypesByDataModel('MOVEMENT_RECORD')"
                      v-model="filtersMovement.reportLayout"
                      class="mt-1"
                      placeholder="Selecione"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      color="textPrimary"
                      item-color="textPrimary"
                      :readonly="isRHProtegido"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>

            <v-card-actions
              class="px-6 pb-3">
              <v-spacer />
              <v-btn color="primary" width="150px" outlined @click="closeFilterExportMovementModal()">
                Cancelar
              </v-btn>
              <v-btn color="primary" width="150px" @click="onClickConfirmFilterExportMovements()" :loading="loadingExportMovementBtn">
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="confirmationDialog" persistent max-width="800">
          <v-card width="800" class="modalExportMovementFiltersTopCenter pa-3 rounded-lg">
            <v-card-title class="text-h5 font-weight-bold" style="color: #3b495b;">
              <v-row
                class="d-flex justify-space-between px-3 pt-3">
                <span>Exportar Movimentações</span>
                <v-icon @click="closeConfirmationModal()">
                  fas fa-times
                </v-icon>
              </v-row>
            </v-card-title>

            <v-card-text class="mt-5">
              <v-row>
                <v-col
                  class="d-flex justify-center align-center flex-column">
                  <v-icon
                    color="green"
                    size="50">
                    mdi mdi-check-circle-outline
                  </v-icon>
                  <p class="font-weight-bold text-body-1 mt-3">
                    Relatório solicitado com sucesso!
                  </p>
                </v-col>
              </v-row>

                <v-alert
                  color="blue lighten-5"
                  class="blue--text">
                  <v-row no-gutters>
                    <v-col
                      cols="1"
                      class="d-flex justify-center align-center">
                      <v-icon
                        color="blue"
                        size="30">
                        mdi mdi-thumb-up-outline
                      </v-icon>
                    </v-col>

                    <v-col
                      cols="11">
                      <p class="font-weight-bold text-body-2">
                        Trouxemos uma nova experiência para a
                        emissão de relatórios de movimentação!
                      </p>
                      <p
                        :class="subTextClass">
                        • Seus relatórios agora ficam na aba
                        <span class="font-weight-bold">"Meus Relatórios"</span>;
                      </p>
                      <p
                        :class="subTextClass">
                        • O histórico das suas emissões estará sempre disponível para consulta e download;
                      </p>
                    </v-col>
                  </v-row>
                </v-alert>
            </v-card-text>

            <v-card-actions
              class="px-6 pb-3">
              <v-spacer />
              <v-btn color="primary" width="150px" outlined @click="closeConfirmationModal()">
                Cancelar
              </v-btn>
              <v-btn color="primary" @click="goToMyReportsTable()">
                Ir para Emissão de Relatórios
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dynamicDialogInformationExportMovement" persistent :max-width="800">
          <v-card class="overflow-y-auto pa-5">
            <v-card-title class="pa-5">
              <v-row justify="center">
                <v-col cols="12" style="color: #4d596a" align="center">
                  <label class="label primary--text font-italic" style="word-break: break-word">“Ops! Algo deu errado. Isso pode ter acontecido por você ter selecionado um grande volume de informações. Tente refinar os filtros e repetir a operação”</label>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-actions>
              <v-row justify="center">
                <v-col align="center" cols="12" xs="12" sm="12" md="3" lg="3">
                  <v-btn
                    color="#3B495B"
                    outlined
                    block
                    @click="dynamicDialogInformationExportMovement = false"
                  >
                    OK
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="120">
            <div>Carregando...</div>
          </v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>

    <ManualCriticismDialog
      :show="manualCriticismDialog.show"
      :propsRules="rules"
      :movementRecordId="manualCriticismDialog.movementId"
      :movementInsuranceCarrierId="manualCriticismDialog.movementInsuranceCarrierId"
      :insuranceCarrierId="manualCriticismDialog.insuranceCarrierId"
      :insuredName="manualCriticismDialog.insuredName"
      :isFreeMovement="manualCriticismDialog.isFreeMovement"
      @close="closeManualCriticismDialog"
      @loadRules="loadRules"
    />

    <CardDialog
      :show="cardDialog.show"
      :movementSelecteds="movementCustoms"
      :beneficiaryToRegister="beneficiaryToRegister"
      :benefits="benefitTypes"
      @close="closeCardDialog()"
      @on-confirm="confirmCard"
    />

    <CardDialogFinish
      :show="cardDialogFinish.show"
      :movementSelecteds="movementCustoms"
      :beneficiaryToRegister="beneficiaryToRegister"
      :benefits="benefitTypes"
      @close="closeCardDialogFinish()"
      @on-confirm="confirmCard"
    />

    <MovementErrorModal
      ref="MovementErrorModal"
      :movementError="propsMovementError"
      :insuredName="propsInsuredName"
      :loadingTableMovementError="propsLoadingTableMovementError"
      :tabError="propsTabError"
    />

    <InfoMovementDialog
      :show="showInfoDialog"
      :beneficiaryName="beneficiaryNameDialog"
      :messageType="messageTypeDialog"
      :actionBlockSuccess="actionBlockSuccess"
      :actionBlockError="actionBlockError"
      @close="closeInfoModal()"
      @showMovementsConfirmInfoModal="showMovementsConfirmInfoModal"
    />

    <ConfirmMovementDialog
      :show="showConfirmDialog" :totalItens="totalItensDialog"
      :messageType="messageTypeConfirmDialog" :loading="loadingConfirmDialog"
      @confirmDialog="confirmActionModal" @close="closeConfirmModal()"
    />

    <UploadMovementsModal
      ref="UploadMovementsModal"
      @verifyCountDocuments="verifyCountDocuments"
    />

    <ExclusionDynamicModal :show="exclusionDynamicModal.show" @close="closeExclusionDynamicModal" @confirm="confirmMovementDelete">
      <template slot="header"> <span class="primary--text">Cancelar Movimentação</span> </template>
      <template slot="content">
        Deseja realmente cancelar esta movimentação? <br>
        Ao cancelar, os beneficiários não serão processados junto à operadora
      </template>
    </ExclusionDynamicModal>

    <SnackbarCustomize ref="SnackbarCustomize" />

    <ViewCriticismAndInternalNotesModal
      ref="ViewCriticismAndInternalNotesModal"
      @setSnackbarCustomize="setSnackbarCustomize"
      @updateMovementTable="selectPagination"
    />

    <CancelMovementBlockModal
      ref="CancelMovementBlockModal"
      :cancellationReasons="cancellationReasons"
      @showResult="getMovements"
      :isMovementFlow="true"
      :isRHProtegido="isRHProtegido"
    />

    <MovementDetailModal
      ref="MovementDetailModal"
    />
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import MovementInsuranceCarrierReturnService from '@/services-http/sdi/MovementInsuranceCarrierReturnService';
import RuleService from '@/services-http/sdi/RuleService';
import MovementErrorService from '@/services-http/sdi/MovementErrorsService';
import ReportService from '@/services-http/sdi/ReportService';
import Functions from '@/shared/functions/functions';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import DocumentService from '@/services-http/sdi/DocumentService';
import { store } from '@/shared/observable/store';
import Rules from '@/shared/validators/formRules';
import { cloneDeep } from 'lodash';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import ManualCriticismDialog from '@/components/DashboardMovementRecords/Movements/Modals/ManualCriticismDialog/ManualCriticismDialog.vue';
import CardDialog from '@/components/DashboardMovementRecords/Movements/Modals/CardDialog/CardDialog.vue';
import CardDialogFinish from '@/components/DashboardMovementRecords/Movements/Modals/CardDialogFinish/CardDialogFinish.vue';
import MovementErrorModal from '@/components/DashboardMovementRecords/Common/MovementErrorModal.vue';
import InfoMovementDialog from '@/components/MovementDialog/InfoMovementDialog.vue';
import ConfirmMovementDialog from '@/components/MovementDialog/ConfirmMovementDialog.vue';
import UploadMovementsModal from '@/components/Beneficiary/Documents/UploadDocumentsModal.vue';
import ExclusionDynamicModal from '@/components/ExclusionDynamicModal/ExclusionDynamicModal.vue';
import ViewCriticismAndInternalNotesModal from '@/components/DashboardMovementRecords/Movements/Modals/ViewCriticismAndInternalNotes/ViewCriticismAndInternalNotesModal.vue';
import AuthorityManager from '@/shared/models/authority-manager.model';
import BenefitService from '@/services-http/odoo/BenefitService';
import myReportsMixin from '@/shared/mixins/myReports/myReportsMixin';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import CancelMovementBlockModal from '@/components/Modals/CancelMovementBlockModal/CancelMovementBlockModal.vue';
import MovementDetailModal from '@/components/Modals/MovementDetailModal/MovementDetailModal.vue';
import CarrierService from '@/services-http/contract/CarrierService';

export default {
  name: 'MovementsTable',

  components: {
    CardDialog,
    SnackbarCustomize,
    TodoListActions,
    ManualCriticismDialog,
    CardDialogFinish,
    MovementErrorModal,
    InfoMovementDialog,
    ConfirmMovementDialog,
    UploadMovementsModal,
    ExclusionDynamicModal,
    ViewCriticismAndInternalNotesModal,
    CancelMovementBlockModal,
    MovementDetailModal
  },

  data: () => ({
    beneficiaryNameDialog: '',
    showInfoDialog: false,
    messageTypeDialog: 'REVERT_TYPE_SUCCESS',

    hasAuthorityReleaseMovement: false,
    hasAuthorityScheduleMovement: false,
    hasAuthorityCancelMovement: false,
    hasAuthorityDetailMovement: false,
    hasAuthorityViewCriticMovement: false,
    hasAuthorityInsertWalletMovement: false,
    hasAuthorityEditMovement: false,
    hasAuthorityViewWalletMovementAction: false,
    hasAuthorityViewCriticAssociationAction: false,
    hasAuthorityViewEndMovementAction: false,
    hasAuthorityViewDocument: false,
    hasAuthorityViewLogs: false,
    hasAuthorityAdvancedMovement: false,
    hasAuthorityViewAllMovements: false,
    dynamicDialogInformationExportMovement: false,
    overlay: false,
    isRHProtegido: false,
    confirmationDialog: false,
    loadingExportMovementBtn: false,
    loadingDependents: false,
    indexInLoading: null,
    carrierService: null,

    headers: [
      {
        text: '',
        value: 'multiselect',
        sortable: false,
      },
      {
        text: 'Protocolo',
        value: 'fileDataId',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Grupo / Operadora',
        value: 'financialGroupId',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Apólice / Subcontrato',
        value: 'policy',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Tipo Movimentação',
        value: 'movementType',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Nome',
        value: 'insuredName',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Tipo Beneficiário',
        value: 'beneficiaryType',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Qtd Dep',
        value: 'dependentsSize',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Status',
        value: 'status',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Críticas',
        value: 'critics',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Erro',
        value: 'hasError',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: '',
        value: 'data-table-expand',
        sortable: false,
      },
    ],
    headersDependents: [
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      {
        text: 'Nome',
        value: 'insuredName',
        align: 'start',
        sortable: false,
      },
      {
        text: 'CPF',
        value: 'insuredDocumentNumber',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Tipo',
        value: 'beneficiaryType',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Grau de parentesco',
        value: 'degreeKinshipDescription',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Status',
        value: 'status',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Críticas',
        value: 'critics',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Erro',
        value: 'hasError',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'start',
        sortable: false,
      },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
    ],
    loadingMovements: false,
    loadingMovementIds: false,
    expandedDependents: [],
    manualCriticismDialog: {
      movementId: 0,
      movementInsuranceCarrierId: 0,
      insuredName: '',
      isFreeMovement: false,
      insuranceCarrierId: 0,
      show: false,
    },
    cardDialog: { show: false },
    cardDialogFinish: { show: false },
    page: 1,
    totalPages: 0,
    movementSelecteds: [],
    movementCustoms: [],
    movementSelectedsWithoutDependents: [],
    isSelectedAllMovements: false,
    payload: {},
    beneficiaryToRegister: [],
    filtersMovement: {},
    rules: [],
    isMultSelect: false,
    propsMovementError: [],
    movements: [],
    propsInsuredName: '',
    propsLoadingTableMovementError: false,
    propsTabError: '',
    isFreeMovement: false,
    filterModal: false,
    isExportMovement: false,
    typeExport: {
      isAllMovements: true,
      onlyPageCurrent: false,
    },
    showConfirmDialog: false,
    loadingConfirmDialog: false,
    actionBlockSuccess: {
      message: '',
      title: '',
    },
    actionBlockError: {
      message: 'Por favor, selecione pelo menos um registro.',
      title: 'Ops... nenhum registro selecionado',
      align: 'center',
    },
    totalItensDialog: 0,
    messageTypeConfirmDialog: 'REVERT_TYPE_ACTION',
    movementRevertItems: [],
    movementReleaseItems: [],
    itemsPerPage: 25,
    options: {},
    filterDocuments: {
      movementRecordId: null,
      countDocuments: 0,
      documents: [],
    },
    countUploadDocuments: 0,
    loadingCountDocuments: false,
    createdComponent: true,
    loadingConfirm: false,
    exclusionDynamicModal: { show: false },
    movementRecordParams: {},
    queryParams: {},
    onlyParameterFilter: {},
    benefitTypes: [],
    subTextClass: "mb-0 pb-0 text-body-2",
    allPossibleMovementsSelected: false,
    showSelectedMovements: false,
    allMovementsSelected: [],
    allMovementIds: [],
    cancellationReasons: [],
  }),

  props: {
    financialGroups: {
      type: Array,
      default: () => [],
    },
    contracts: {
      type: Array,
      default: () => [],
    },
    subContracts: {
      type: Array,
      default: () => [],
    },
  },

  mixins: [
    VerifyRoutesMixin,
    AuthorityManagementMixin,
    myReportsMixin,
    GenericFunctionsMixin,
  ],

  async created() {
    this.formatter = new Formatters();
    this.ruleService = new RuleService();
    this.movementErrorService = new MovementErrorService();
    this.reportService = new ReportService();
    this.functions = new Functions();
    this.documentService = new DocumentService();
    this.movementRecordService = new MovementRecordService();
    this.rule = new Rules();
    this.benefitService = new BenefitService();
    this.carrierService = new CarrierService();
    await this.loadAuthorityMovement();
  },

  async mounted() {
    this.loadRules();
    this.loadBenetiType();
    this.cancellationReasons = await this.movementRecordService.GetReasonCancellation();
  },

  methods: {
    async getCarrierByIds(insuranceCarrierIds) {
      return await this.carrierService.FindByIds(insuranceCarrierIds)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          this.setSnackbarCustomize(
            'error',
            'Ocorreu um erro ao buscar os dados da operadora.'
          );
        })
    },
    insertCriticismValidation(item) {
      if (['INVALID_IN_CARRIER', 'IN_REVIEW'].includes(item.status)) return false

      return this.insertCriticismAdditionalValidation(item) ? false : true;
    },
    insertCriticismDependentsValidation(dependent, holder) {
      return holder.status === 'SUBMITTED'
        && this.insertCriticismAdditionalDependentsValidation(dependent, holder) ? false : true;
    },
    insertCriticismAdditionalValidation(holder) {
      const dependentsValidation = this.dependentStatusValidation(holder.dependents);
      const xippEnableIndividualCorrection = this.insertCriticismXippEnableIndividualCorrectionValidation(holder);

      return holder.status === 'SUBMITTED'
        && dependentsValidation
        && xippEnableIndividualCorrection;
    },
    insertCriticismAdditionalDependentsValidation(dependent, holder) {
      const dependentValidation = ['INVALID_IN_CARRIER', 'IN_REVIEW'].includes(dependent.status);
      const xippEnableIndividualCorrection = this.insertCriticismXippEnableIndividualCorrectionValidation(holder);

      return dependentValidation && !xippEnableIndividualCorrection;
    },
    insertCriticismXippEnableIndividualCorrectionValidation(item) {
      if (item && !item.movementCarrier || Object.keys(item.movementCarrier).length === 0) return false;
      return item.movementCarrier.xippEnableIndividualCorrection === true ? true : false;
    },
    dependentStatusValidation(dependents) {
      if (dependents.length === 0) return false;
      return dependents.some(el => ['INVALID_IN_CARRIER', 'IN_REVIEW'].includes(el.status));
    },
    openMovementDetailsModal(item) {
      this.$refs.MovementDetailModal.open(item);
    },
    getFilterMovement() {
      this.resetParamsFilter();
      this.getMovements();
    },
    async loadAuthorityMovement() {
      this.hasAuthorityReleaseMovement = AuthorityManager.getAuthorityReleaseMovement();
      this.hasAuthorityScheduleMovement = AuthorityManager.getAuthorityScheduleMovement();
      this.hasAuthorityCancelMovement = AuthorityManager.getAuthorityCancelMovement();
      this.hasAuthorityDetailMovement = AuthorityManager.getAuthorityDetailMovement();
      this.hasAuthorityViewCriticMovement = AuthorityManager.getAuthorityViewCriticMovement();
      this.hasAuthorityInsertWalletMovement = AuthorityManager.getAuthorityInsertWalletMovement(); // não utilizada deixar aqui até resolver se mudaremos nome dela.
      this.hasAuthorityEditMovement = AuthorityManager.getAuthorityEditMovement();
      this.hasAuthorityViewWalletMovementAction = AuthorityManager.getAuthorityViewWalletMovementAction();
      this.hasAuthorityViewCriticAssociationAction = AuthorityManager.getAuthorityViewCriticAssociationAction();
      this.hasAuthorityViewEndMovementAction = AuthorityManager.getAuthorityViewEndMovementAction();
      this.hasAuthorityViewDocument = AuthorityManager.getAuthorityViewDocument();
      this.hasAuthorityViewLogs = AuthorityManager.getAuthorityViewLogs();
      this.hasAuthorityAdvancedMovement = AuthorityManager.getAdvancedMovement();
      this.hasAuthorityViewAllMovements = AuthorityManager.getAuthorityViewAllMovements();
    },
    getActionsMovementsForItem(item) {
      const actions = [
        {
          label: 'Finalizar Movimentação',
          icon: 'fas fa-check',
          color: 'textPrimary',
          condition: this.hasAuthorityViewEndMovementAction && !this.hasAuthorityAdvancedMovement,
          disabled: this.controlDisableFieldEndMovement(item),
          handler: () => this.endMovement(item),
        },
        {
          label: 'Editar Movimentação',
          icon: 'fa fa-edit',
          color: 'textPrimary',
          condition: this.hasAuthorityEditMovement,
          disabled: (item.status !== 'WAITING_APPROVAL_RULES_VALIDATIONS' && item.status !== 'WAIT_SUPPORT') || (item.status === 'WAIT_SUPPORT' && this.isRHProtegido),
          handler: () => this.editMovementRecord(item),
        },
        {
          label: 'Liberar Movimentação',
          icon: 'fas fa-clipboard-check',
          color: 'textPrimary',
          condition: this.hasAuthorityReleaseMovement && item.status === 'WAITING_APPROVAL_RULES_VALIDATIONS',
          disabled: this.controlDisableFieldReleaseMovement(item),
          handler: () => this.updateStatus(item),
        },
        {
          label: 'Reverter Liberação',
          icon: 'fas fa-undo',
          color: 'textPrimary',
          condition: this.hasAuthorityReleaseMovement && (item.status === 'VALID' || item.status === 'ADJUSTED'),
          disabled: item.movementInsuranceCarrierId !== null && this.isRHProtegido,
          handler: () => this.updateStatus(item),
        },
        {
          label: 'Solicitar Liberação',
          icon: 'mdi mdi-calendar-clock-outline',
          color: 'textPrimary',
          condition: this.hasAuthorityScheduleMovement && item.status === 'SCHEDULED',
          handler: () => this.requestReleaseFromScheduled(item),
        },
        {
          label: `Documentos ${!this.loadingCountDocuments && this.filterDocuments && this.filterDocuments.countDocuments ? `(${this.filterDocuments.countDocuments})` : !this.loadingCountDocuments ? '(0)' : ''}`,
          icon: 'fas fa-paperclip',
          color: 'textPrimary',
          loadingCountDocuments: this.loadingCountDocuments,
          condition: this.hasAuthorityViewDocument,
          disabled: this.loadingCountDocuments,
          handler: () => this.openModalToAttachDocuments(item),
        },
        {
          label: 'Cancelar Movimentação',
          icon: 'fas fa-trash',
          color: 'textPrimary',
          condition: this.hasAuthorityCancelMovement,
          disabled: this.controlCanceledHolderMovement(item),
          handler: () => this.openExclusionDynamicModal(item),
        },
        {
          label: 'Visualizar Logs',
          icon: 'fas fa-database',
          color: 'textPrimary',
          condition: this.hasAuthorityViewLogs,
          handler: () => this.onClickViewLogs(item.id),
        },
        {
          label: 'Finalizar movimentação',
          icon: 'fas fa-address-card',
          color: 'textPrimary',
          condition: this.hasAuthorityAdvancedMovement && (item.status !== 'SUCCESS' && item.status !== 'FINISH_WITH_CRITICAL' && item.status !== 'CANCELED') && !this.isRHProtegido,
          disabled: this.checkForceFinishDisable(item),
          handler: () => this.openCardDialogFinish(item),
        },
        {
          label: 'Reenviar Movimentação',
          icon: 'fas fa-redo-alt',
          color: 'textPrimary',
          size: '25',
          condition: !this.hasAuthorityViewButtonResendMovement && item.status === 'IN_REVIEW',
          disabled: this.isRHProtegido,
          handler: () => this.openCriticismDialog(item, null, 'resendMovement'),
        },
      ];

      actions.sort((a, b) => a.label.localeCompare(b.label));

      return actions;
    },
    getActionsDependentsForItem(item, itemParent) {
      const actions = [
        {
          label: 'Cancelar Movimentações',
          icon: 'fas fa-trash',
          condition: this.hasAuthorityCancelMovement,
          color: itemParent.status !== 'SUCCESS' || (item.status === 'INVALID_IN_CARRIER' && item.movementInsuranceCarrierId !== null) ? 'grey' : 'textPrimary',
          disabled: itemParent.status !== 'SUCCESS' || (item.status === 'INVALID_IN_CARRIER' && item.movementInsuranceCarrierId !== null),
          handler: () => this.openExclusionDynamicModal(item),
        },
        {
          label: 'Reenviar Movimentação',
          icon: 'fas fa-redo-alt',
          color: 'textPrimary',
          condition: this.hasAuthorityViewButtonResendMovement && itemParent.status !== 'IN_REVIEW' && item.status !== 'IN_REVIEW',
          handler: () => this.openCriticismDialog(item, itemParent, 'resendMovement'),
        },
        {
          label: 'Finalizar Movimentação',
          icon: 'fas fa-address-card',
          color: 'textPrimary',
          condition: this.hasAuthorityAdvancedMovement && (item.status !== 'SUCCESS' && item.status !== 'FINISH_WITH_CRITICAL' && item.status !== 'CANCELED') && !this.isRHProtegido,
          disabled: this.checkForceFinishDisable(item),
          handler: () => this.openCardDialogFinish(item, itemParent, 'resendMovement'),
        },
      ];

      actions.sort((a, b) => a.label.localeCompare(b.label));

      return actions;
    },
    loadRules() {
      this.ruleService.FindAll().then((response) => {
        if (response && response.data.length > 0) {
          this.rules = response.data;
        }
      });
    },
    selectAllMovements() {
      this.movementSelecteds = [];
      this.movementCustoms = [];
      this.movementSelectedsWithoutDependents = [];
      if (!this.isSelectedAllMovements) {
        if (this.movements && this.movements.length > 0) {
          this.movementSelecteds = [];
          this.movementCustoms = [];

          if (this.movements && this.movements.length > 0) {
            this.movements.forEach((movementHolder) => {
              if (movementHolder) {
                this.movementSelecteds.push(movementHolder.id);
                this.movementCustoms.push(movementHolder);
                this.movementSelectedsWithoutDependents.push(movementHolder);
              }
              if (movementHolder && movementHolder.dependents && movementHolder.dependents.length > 0) {
                if (movementHolder.dependents && movementHolder.dependents.length > 0) {
                  movementHolder.dependents.forEach((dependent) => {
                    if (dependent) {
                      // this.movementSelecteds.push(dependent.id);
                      this.movementCustoms.push(dependent);
                    }
                  });
                }
              }
            });
          }
        }
        this.findAllIds();
        this.allMovementsSelected = this.movementSelecteds;
        this.isMultSelect = true;
      } else {
        this.isMultSelect = false;
      }
      this.isSelectedAllMovements = !this.isSelectedAllMovements;
    },
    async openCriticismDialog(movement, movementParent, viewType) {
      this.clearSessionStorage();
      await this.loadDependentsByMovementRecord(movement);
      
      const queryParams = {
        movementRecord: movement,
        movementRecordParant: movementParent,
        isRHProtegido: this.isRHProtegido,
        viewType,
      };
      this.$refs.ViewCriticismAndInternalNotesModal.open(queryParams);
    },
    openManualCriticismDialog(item) {
      this.manualCriticismDialog.movementInsuranceCarrierId = item.movementInsuranceCarrierId;
      this.manualCriticismDialog.movementId = item.id;
      this.manualCriticismDialog.insuredName = item.insuredName;
      this.manualCriticismDialog.isFreeMovement = item.freeMovement;
      this.manualCriticismDialog.insuranceCarrierId = item.insuranceCarrierId;
      this.manualCriticismDialog.show = true;
    },
    async selectPagination() {
      if (this.isExportMovement) {
        /*
        * Definido limite fixo temporariamente de exportação de movimentações
        * em 32767 no frontend conforme atividade XIPP-9974.
        */
        if (this.totalPages >= 32767) {
          this.setSnackbarCustomize(
            'error',
            'O limite para exportar relatórios é de 32767 movimentações. Por gentileza, realize outra pesquisa com uma quantidade menor de movimentações'
          );
          return;
        }

        await this.exportMovements();
        this.closeFilterExportMovementModal();
      } else {
        this.getMovements();
      }
    },
    async applyForCard(item, type) {
      const isHolderSelected = this.movementSelecteds.some((element) => element === item.id);
      const isDependentSelected = item.dependents.filter((dependent) => this.movementSelecteds.some((element) => dependent.id === element));
      if (!this.isSelectedAllMovements && (isHolderSelected || (isDependentSelected && isDependentSelected.length === 0))) {
        if (type === 'efetivation') {
          await this.incrementDependentSelected(item);
        }
        this.movementSelecteds.forEach((selected) => {
          if (item.id === selected) {
            this.movementCustoms.push(item);
          }
          if (item.dependents && item.dependents.length > 0) {
            item.dependents.forEach((dependent) => {
              if (dependent.id === selected) {
                this.movementCustoms.push(dependent);
              }
            });
          }
        });
      } else {
        this.removeBeneficiaryCard(item);
      }
    },
    async selectedValidMovements(item) {
      const movementInsuranceCarrierReturnService = new MovementInsuranceCarrierReturnService();
      const listAux = [];
      if ((item.beneficiaryCard === null || item.beneficiaryCard === '') || (item.familyCode === null || item.familyCode === '') || (item.familyCodeSequential === null || item.familyCodeSequential === '')) {
        listAux.push('yes');
      } else {
        listAux.push('no');
      }
      if (item.dependents && item.dependents.length > 0) {
        item.dependents.forEach((element) => {
          if (element.beneficiaryCard === null || element.beneficiaryCard === '' || (element.familyCode === null || element.familyCode === '') || (element.familyCodeSequential === null || element.familyCodeSequential === '')) {
            listAux.push('yes');
          } else {
            listAux.push('no');
          }
        });
      }

      if (listAux.includes('yes')) {
        this.movementCustoms.push(item);
        item.dependents.forEach((dependent) => {
          this.movementCustoms.push(dependent);
        });
      } else {
        await this.returnInsuranceCarrierWithBenefitTypeNotCondition(item);
        movementInsuranceCarrierReturnService.Save(this.beneficiaryToRegister, this.isFreeMovement).then(() => {
          this.selectPagination();
          this.setSnackbarCustomize('success', 'Movimentação finalizada com sucesso');
        }).catch(() => {
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao tentar finalizar a movimentação');
        });
      }
      if (this.movementCustoms && this.movementCustoms.length > 0) {
        this.openCardDialog();
      }
    },
    async incrementDependentSelected(item) {
      if (item.status === 'SUBMITTED' && !this.isSelectedAllMovements) {
        if (item.dependents && item.dependents.length > 0) {
          await item.dependents.forEach((dependent) => {
            const existingDependent = this.movementSelecteds.filter((isDependentId) => isDependentId === dependent.id);
            if (dependent.status === 'SUBMITTED' && existingDependent.length === 0) {
              this.movementSelecteds.push(dependent.id);
            }
          });
        }
      }
    },
    removeBeneficiaryCard(item) {
      this.movementSelecteds.forEach((element, index) => {
        const isDependentSelected = item.dependents.some((dependent) => dependent.id === element);
        if (isDependentSelected) {
          this.movementSelecteds.splice(index, 1);
        }
      });

      this.movementCustoms = this.movementCustoms.filter((movementHolder) => movementHolder && movementHolder.id !== item.id);

      if (item.dependents && item.dependents.length > 0) {
        this.movementCustoms = this.movementCustoms.filter((movementDependent) => !item.dependents.some((dependent) => dependent.id === movementDependent.id));
      }
    },
    openCardDialog() {
      this.cardDialog.show = true;
    },
    openCardDialogFinishInBlock() {
      this.cardDialogFinish.show = true;
    },
    async openCardDialogFinish(item) {
      await this.loadDependentsByMovementRecord(item);
      this.cardDialogFinish.show = true;
      this.movementCustoms = [];
      this.movementCustoms.push(item);
      item.dependents.forEach((dep) => this.movementCustoms.push(dep));
    },
    openMovementDetailPage(item) {
      if (this.hasAuthorityDetailMovement) {
        sessionStorage.setItem('movementRecord', JSON.stringify(item));

        this.setQueryForRedirection();

        this.$router.push({
          name: 'MovementDetail',
          query: {
            id: item.id,
            freeMovement: item.freeMovement,
            isRHProtegido: `${this.isRHProtegido}`,
            movementType: item.movementType,
            insuranceCarrierId: item.insuranceCarrierId,
            beneficiaryType: item.beneficiaryType,
          },
        });
      }
    },
    closeManualCriticismDialog(isModified) {
      if (isModified) {
        this.selectPagination();
      }
      this.manualCriticismDialog.show = false;
    },
    closeCardDialog() {
      this.cardDialog.show = false;
      this.movementSelecteds = [];
      this.movementCustoms = [];
      this.movementSelectedsWithoutDependents = [];
      this.isMultSelect = false;
      this.isSelectedAllMovements = false;
    },
    closeCardDialogFinish() {
      this.cardDialogFinish.show = false;
      this.movementSelecteds = [];
      this.movementCustoms = [];
      this.movementSelectedsWithoutDependents = [];
      this.isMultSelect = false;
      this.isSelectedAllMovements = false;
    },
    closeInfoModal() {
      this.showInfoDialog = false;
      this.beneficiaryNameDialog = '';
      this.resetActionBlockError();
      this.resetActionBlockSuccess();

      if (this.messageTypeDialog === 'REVERT_TYPE_SUCCESS') {
        this.selectPagination();
      } else if (this.messageTypeDialog === 'ACTION_BLOCK_TYPE_SUCCESS') {
        this.clearControllParameters();
        this.selectPagination();
      }

      this.messageTypeDialog = 'REVERT_TYPE_SUCCESS';
    },
    showMovementsConfirmInfoModal() {
      if (this.messageTypeConfirmDialog === 'RELEASE_TYPE_ACTION' && this.movementReleaseItems.length) {
        this.movementReleaseItems = this.movementReleaseItems.map((item) => ({
          ...item,
          status: 'VALID',
        }));
        this.movements = this.movementReleaseItems;
        this.clearPageControll();
        this.clearControllParameters();
      } else if (this.messageTypeConfirmDialog === 'REVERT_TYPE_ACTION' && this.movementRevertItems.length) {
        this.movementRevertItems = this.movementRevertItems.map((item) => ({
          ...item,
          status: 'WAITING_APPROVAL_RULES_VALIDATIONS',
        }));
        this.movements = this.movementRevertItems;
        this.clearPageControll();
        this.clearControllParameters();
      } else {
        this.closeInfoModal();
      }
    },
    async updateStatus(item) {
      const movementRecordService = new MovementRecordService();
      if (this.hasAuthorityReleaseMovement) {
        if (item.status === 'WAITING_APPROVAL_RULES_VALIDATIONS') {
          await movementRecordService.UpdateStatus(item.id, 'VALID').then(() => {
            this.selectPagination();
            this.setSnackbarCustomize('success', 'Movimentação liberada com sucesso');
          }).catch(() => {
            this.setSnackbarCustomize('error', 'Ocorreu um erro ao liberar a movimentação');
          });
        } else if (item.status === 'VALID' || item.status === 'ADJUSTED') {
          await movementRecordService.UpdateStatus(item.id, 'WAITING_APPROVAL_RULES_VALIDATIONS').then(() => {
            this.messageTypeDialog = 'REVERT_TYPE_SUCCESS';
            this.beneficiaryNameDialog = item && item.insuredName ? item.insuredName : '';
            this.showInfoDialog = true;
          }).catch(() => {
            this.messageTypeDialog = 'REVERT_TYPE_ERROR';
            this.showInfoDialog = false;
          });
        } else {
          this.setSnackbarCustomize('error', 'Operação não permitida!');
        }
      } else {
        this.setSnackbarCustomize('error', 'Você não tem permissão para liberar a movimentação');
      }
    },
    async requestReleaseFromScheduled(item) {
      if (this.hasAuthorityScheduleMovement && item.status === 'SCHEDULED') {
        await this.handleScheduled(item.id);
      } else {
        this.setSnackbarCustomize('error', 'Você não tem permissão efetuar essa ação');
      }
    },
    async handleScheduled(itemId) {
      const movementRecordService = new MovementRecordService();
      await movementRecordService.UpdateStatus(itemId, 'WAITING_APPROVAL_RULES_VALIDATIONS');
      this.selectPagination();
      this.setSnackbarCustomize('success', 'Liberação agendada com sucesso');
    },
    async editMovementRecord(item) {
      await this.loadDependentsByMovementRecord(item);
      if (this.hasAuthorityEditMovement) {
        if (item.status === 'WAITING_APPROVAL_RULES_VALIDATIONS' || item.status === 'SCHEDULED' || item.status === 'WAIT_SUPPORT') {
          let routeName;
          sessionStorage.setItem('movementRecord', JSON.stringify(item));
          sessionStorage.setItem('isEdit', true);
          const query = {
            movementRecordId: item.id,
            beneficiaryType: item.beneficiaryType,
            isRHProtegido: this.isRHProtegido,
            freeMovement: item.freeMovement,
            insuranceCarrierId: item.insuranceCarrierId,
            movementType: item.movementType,
          };

          if (item.beneficiaryType === 'HOLDER') {
            if (item.movementType === 'DELETE') {
              routeName = 'HolderDelete';
            }
            if (item.movementType === 'UPDATE') {
              routeName = 'Alteration';
            }
            if (item.movementType === 'INSERT') {
              routeName = 'FamilyGroupInclusionUpdate';
            }
          }

          if (item.beneficiaryType === 'DEPENDENT') {
            if (item.movementType === 'DELETE_DEPENDENT') {
              routeName = 'DependentDelete';
            }
            if (item.movementType === 'INSERT_DEPENDENT') {
              routeName = 'DependentUpdate';
            }
            if (item.movementType === 'UPDATE_DEPENDENT') {
              routeName = 'DependentAlteration';
            }
          }

          this.redirectRouter(routeName, query);
        } else {
          this.setSnackbarCustomize('error', 'Operação não permitida!');
        }
      } else {
        this.setSnackbarCustomize('error', 'Você não tem permissão para editar a movimentação');
      }
    },
    async endMovement(item) {
      await this.loadDependentsByMovementRecord(item);
      const movementInsuranceCarrierReturnService = new MovementInsuranceCarrierReturnService();
      this.movementSelecteds = [];
      this.beneficiaryToRegister = [];
      this.movementCustoms = [];
      if (item && item.freeMovement) {
        this.isFreeMovement = true;
      }
      if (item && ((item.status === 'SUBMITTED') || (item.freeMovement && (item.status === 'VALID' || item.status === 'ADJUSTED')))) {
        if ((item.movementType && (item.movementType === 'INSERT' || item.movementType === 'INSERT_DEPENDENT')) && (item.benefitType && (item.benefitType.toUpperCase() === 'HEALTH' || item.benefitType.toUpperCase() === 'DENTAL'))) {
          this.selectedValidMovements(item);
        } else {
          await this.returnInsuranceCarrierWithBenefitTypeNotCondition(item);
          movementInsuranceCarrierReturnService.Save(this.beneficiaryToRegister, this.isFreeMovement).then(() => {
            this.selectPagination();
            this.setSnackbarCustomize('success', 'Movimentação finalizada com sucesso');
          }).catch(() => {
            this.setSnackbarCustomize('error', 'Ocorreu um erro ao tentar finalizar a movimentação');
          });
        }
      } else {
        this.setSnackbarCustomize('error', 'Operação não permitida!');
      }
    },
    confirmCard(confirm) {
      if (confirm) {
        this.closeCardDialog();
        this.closeCardDialogFinish();
        this.selectPagination();
      }
    },
    async returnInsuranceCarrierWithBenefitTypeNotCondition(item) {
      this.beneficiaryToRegister.push({
        movementRecordId: item.id,
        returnType: 'SUCCESS',
        movementInsuranceCarrierId: item.movementInsuranceCarrierId,
        familyCode: item.familyCode,
        familyCodeSequential: item.familyCodeSequential
      });
      if (item && item.dependents.length > 0) {
        item.dependents.forEach((element) => {
          if (element && element.status === 'SUBMITTED') {
            this.beneficiaryToRegister.push({
              movementRecordId: element.id,
              returnType: 'SUCCESS',
              movementInsuranceCarrierId: element.movementInsuranceCarrierId,
              familyCode: item.familyCode,
              familyCodeSequential: item.familyCodeSequential,
            });
          }
        });
      }
    },
    openMovementErrorModal(item, tabError) {
      if (item) {
        this.propsTabError = tabError;
        this.propsInsuredName = item.insuredName;
        this.propsLoadingTableMovementError = true;
        const movementRecordId = `?movementRecordId=${item.id}`;
        this.movementErrorService.FindAllByFilters(movementRecordId).then((response) => {
          if (response && response.data && response.data.length > 0) {
            this.propsMovementError = response.data;
          }
          this.propsLoadingTableMovementError = false;
        }).catch(() => {
          this.propsLoadingTableMovementError = false;
        });
      }
      this.$refs.MovementErrorModal.openModal();
    },
    controlDisableFieldReleaseMovement(item) {
      const hasAuthorityAndStatus = (this.hasAuthorityReleaseMovement && item.status === 'WAITING_APPROVAL_RULES_VALIDATIONS');

      if (item.dependents && item.dependents.length > 0 && hasAuthorityAndStatus) {
        const hasDependentsNotWaitingApproval = item.dependents.some((dependent) => dependent.status !== 'WAITING_APPROVAL_RULES_VALIDATIONS');
        return hasDependentsNotWaitingApproval;
      }

      return !hasAuthorityAndStatus;
    },
    controlDisableFieldEndMovement(item) {
      if (item && item.insuranceCarrierId !== 5299) {
        if (!item.freeMovement && item.status === 'SUBMITTED') return false;
        if (item.freeMovement && (item.status === 'VALID' || item.status === 'ADJUSTED')) return false;
      }
      return true;
    },
    controlDisableManualCriticismMovement(item) {
      if (item) {
        if (!item.freeMovement && (item.status === 'SUBMITTED' || item.status === 'INVALID_IN_CARRIER')) return false;
        if (item.freeMovement && (item.status === 'VALID' || item.status === 'ADJUSTED' || item.status === 'SUBMITTED')) return false;
      }
      return true;
    },
    controlCanceledHolderMovement(item) {
      if (item) {
        if (this.allPossibleMovementsSelected) return true;
        if ((
          (
            item.status !== 'CANCELED' &&
            item.status !== 'SUBMITTED' &&
            item.status !== 'SUCCESS' &&
            item.status !== 'WAIT_SUBMISSION') &&
            item.movementInsuranceCarrierId === null)) {
          return false;
        }
      }
      return true;
    },
    checkForceFinishDisable(item) {
      if(item) {
        if(item.status === 'WAITING_APPROVAL_RULES_VALIDATIONS' || item.status === 'SCHEDULED' ||
           (item.status === 'VALID' && item.movementInsuranceCarrierId !== null) || item.status === 'SUBMITTED' || item.status === 'IN_REVIEW' ||
           item.status === 'INVALID' || item.status === 'INVALID_IN_CARRIER' || item.status === 'WAIT_SUPPORT')

           return false;
      }
      return true;
    },
    openFilterExportMovementModal() {
      this.filtersMovement.reportLayout = "REPORT_MOVEMENT_RECORD_DEFAULT"
      this.filterModal = true;
    },
    closeFilterExportMovementModal() {
      this.filterModal = false;
    },
    closeConfirmationModal() {
      this.confirmationDialog = false;
    },
    async onClickConfirmFilterExportMovements() {
      this.loadingExportMovementBtn = true;
      this.isExportMovement = true;
      await this.selectPagination();
      this.loadingExportMovementBtn = false;
    },
    goToMyReportsTable() {
      this.confirmationDialog = false;
      this.$emit("goToMyReportsTable");
    },
    checkRulesStatusViolation(item) {
      if (item) {
        if ((item.status === 'INVALID' || item.status === 'INVALID_IN_CARRIER')) {
          return true;
        }
      }
      return false;
    },
    checkRulesViolation(item) {
      if (item) {
        if ((item.hasRulesViolation || item.hasReturnViolation)) {
          return true;
        }
      }
      return false;
    },
    checkCriticismValidation(item) {
      return this.checkRulesStatusViolation(item) && !this.checkRulesViolation(item);
    },
    checkDependentReturnViolation(item) {
      if (item.status !== 'INVALID_IN_CARRIER' && item.status !== 'INVALID') {
        if (item.dependents && item.dependents.length > 0) {
          if (item.dependents.some((dependent) => dependent.status === 'INVALID_IN_CARRIER' && dependent.hasReturnViolation)) {
            return true;
          }
          if (item.dependents.some((dependent) => dependent.status === 'INVALID' && dependent.hasRulesViolation)) {
            return true;
          }
        }
      }
      return false;
    },
    checkDependentReturnViolationStatus(item) {
      if (item.status !== 'INVALID_IN_CARRIER' && item.status !== 'INVALID') {
        if (item.dependents && item.dependents.length > 0) {
          if (item.dependents.some((dependent) => dependent.status === 'INVALID_IN_CARRIER' && dependent.hasReturnViolation)) {
            return 'Verifique as críticas das demais movimentações desse grupo familiar';
          }
          if (item.dependents.some((dependent) => dependent.status === 'INVALID' && dependent.hasRulesViolation)) {
            return 'Verifique as críticas das demais movimentações desse grupo familiar';
          }
        }
      }
      return this.formatter.formatStatusMessage(item.status, item.beneficaryIntegrationStatus);
    },
    async findAllIds() {
      this.loadingMovementIds = true;

      const filteredParams = await this.getFilteredParams();
      const params = await this.buildSearchParams(filteredParams, 'search');
      const movementRecordService = new MovementRecordService();

      try {
        const response = await movementRecordService.FindAllIds(params);
        this.allMovementIds = response.data
        this.showSelectedMovements = true;
      } catch (error) {
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao buscar as movimentações');
      } finally {
        this.loadingMovementIds = false;
      }
    },
    async getMovements() {
      if (this.validatorFiltersParams()) {
        this.createdComponent = false;
        this.loadingMovements = true;
        this.clearControllParameters();

        const filteredParams = await this.getFilteredParams();

        if (
          'status' in filteredParams
          && filteredParams.status.length > 0
          && filteredParams.status.includes("SUBMITTED")
        ) {
          filteredParams.withDependents = true;
        }

        const params = await this.buildSearchParams(filteredParams, 'search');
        const movementRecordService = new MovementRecordService();
        try {
          const response = await movementRecordService.FindByParams(params);

          if (this.responseIsValid(response)) {
            await this.updateMovements(response.data.content, response.data.totalElements);
            sessionStorage.removeItem('movementRecordIds');
          } else {
            this.movements = [];
            this.totalPages = 0;
          }
          this.hasDependentWithError();
        } catch (error) {
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao buscar as movimentações');
        } finally {
          this.isMultSelect = false;
          this.isSelectedAllMovements = false;
          this.createdComponent = false;
          this.loadingMovements = false;
        }
      } else {
        this.setSnackbarCustomize('error', 'Preencha os campos corretamente');
      }
    },
    async getCanceledMovements() {
      this.loadingMovements = true;

      this.clearControllParameters();

      const params = new URLSearchParams({
				movementIds: this.allMovementsSelected,
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: 'id,desc',
			})

      const movementRecordService = new MovementRecordService();
      try {
        const response = await movementRecordService.FindByParams(params);

        if (this.responseIsValid(response)) {
          await this.updateMovements(response.data.content, response.data.totalElements);
          sessionStorage.removeItem('movementRecordIds');
        } else {
          this.movements = [];
          this.totalPages = 0;
        }
        this.hasDependentWithError();
      } catch (error) {
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao buscar as movimentações');
      } finally {
        this.isMultSelect = false;
        this.isSelectedAllMovements = false;
        this.createdComponent = false;
        this.loadingMovements = false;
      }
    },
    validatorFiltersParams() {
      const parametersToCheck = [
        this.filtersMovement.protocolNumber,
        this.filtersMovement.movementRecordId,
        this.filtersMovement.movementInsuranceCarrierId,
        this.filtersMovement.createdBy,
        this.filtersMovement.reportLayout
      ];

      const allParametersValid = parametersToCheck.every((parameter) => this.verifyParameterNumber(parameter));

      const nameValid = this.verifyParameterName(this.filtersMovement.name);

      const cpfValid = this.rule.cpf(this.filtersMovement.document) === true;

      return allParametersValid && nameValid && cpfValid;
    },
    async exportMovements() {
      this.setOverlay(true);
      this.isExportMovement = false;
      const filteredParams = await this.getFilteredParams();
      const params = await this.buildSearchParams(filteredParams, 'export');

      await this.reportService.findReportFileByParams(params).then(async (response) => {
        // await this.functions.downloadFile(response);
        // this.setOverlay(false);
        // this.isExportMovement = false;
        // this.typeExport.isAllMovements = true;
        // this.typeExport.onlyPageCurrent = false;
        this.setSnackbarCustomize('success', 'Movimentações exportadas com sucesso!');
        this.confirmationDialog = true;
        this.loadingExportMovementBtn = false;
        this.setOverlay(false);
      }).catch(() => {
        this.loadingExportMovementBtn = false;
        this.setOverlay(false);
        this.dynamicDialogInformationExportMovement = true;
      });
    },
    async getFilteredParams() {
      return Object.entries(this.filtersMovement).filter(([key, value]) => key === 'internalCriticism' || (value && (value !== ''))).reduce((element, [key, value]) => ({ ...element, [key]: value }), {});
    },
    async buildSearchParams(filteredParams, type) {
      const formattedFilterParams = cloneDeep(filteredParams);

      await this.formatDateParams(formattedFilterParams);
      this.onlyParameterFilter = formattedFilterParams;

      let movementIds = [];
      if (sessionStorage.getItem('movementRecordIds')) {
        movementIds = JSON.parse(sessionStorage.getItem('movementRecordIds'));
      }

      const queryParams = {
        ...formattedFilterParams,
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: 'id,desc',
        movementIds,
      };

      let contractSelected = {};
      let subContractSelected = {};
      if (queryParams.contract) {
        if (this.contracts && this.contracts.length > 0) {
          contractSelected = this.contracts.find((contract) => contract.id === queryParams.contract);
          if (contractSelected) {
            queryParams.contract = contractSelected.policy;
          }
        }
      }
      if (queryParams.subContract) {
        if (this.subContracts && this.subContracts.length > 0) {
          subContractSelected = this.subContracts.find((subContract) => subContract.id === queryParams.subContract);
          if (subContractSelected) {
            queryParams.subContract = subContractSelected.subcontract_number;
          }
        }
      }

      if (type === 'export') {
        queryParams.isAllMovements = this.typeExport.isAllMovements;
      }

      this.deleteParamsEmpty(queryParams);
      if (queryParams.document) {
        queryParams.document = this.formatter.removeNonDigit(queryParams.document);
      }

      this.queryParams = queryParams;

      const searchParams = new URLSearchParams(queryParams);

      return searchParams;
    },
    deleteParamsEmpty(queryParams) {
      delete queryParams.dateType;
      delete queryParams.startDate;
      delete queryParams.endDate;

      if (queryParams.movementType && queryParams.movementType.length === 0) {
        delete queryParams.movementType;
      }
      if (queryParams.status && queryParams.status.length === 0) {
        delete queryParams.status;
      }
      if (queryParams.insuranceCarrierIds && queryParams.insuranceCarrierIds.length === 0) {
        delete queryParams.insuranceCarrierIds;
      }
      if (queryParams.appOrigin && queryParams.appOrigin === 'allChannels') {
        delete queryParams.appOrigin;
      }
      if (queryParams.internalCriticism === '') {
        delete queryParams.internalCriticism;
      }
      if (queryParams.internalCriticism === 'both') {
        delete queryParams.internalCriticism;
      }
      if (queryParams.movementIds.length === 0) {
        delete queryParams.movementIds;
      }
    },
    async formatDateParams(params) {
      const dateTypeMappings = {
        isMovementDate: 'isMovementDate',
        isCarrierSendDate: 'isCarrierSendDate',
        isCarrierFinishedDate: 'isCarrierFinishedDate',
        isValidityDate: 'isValidityDate',
        isSendDate: 'isSendDate',
      };

      if (params.dateType && Array.isArray(params.dateType)) {
        Object.keys(dateTypeMappings).forEach((dateType) => {
          if (params.dateType.includes(dateType)) {
            params[dateTypeMappings[dateType]] = true;
          }
        });
      }

      params.receivingDateStart = params.receivingDateStart ? params.receivingDateStart : params.startDate;
      params.receivingDateEnd = params.receivingDateEnd ? params.receivingDateEnd : params.endDate;
      if ((typeof params.receivingDateStart === 'undefined' || params.receivingDateStart === '') || (typeof params.receivingDateEnd === 'undefined' || params.receivingDateEnd === '')) {
        delete params.receivingDateStart;
        delete params.receivingDateEnd;
      }
    },
    responseIsValid(response) {
      return response && response.data && response.data.content && response.data.content.length > 0;
    },
    async updateMovements(content, totalElements) {
      const movementInsuranceCarrierIds = [... new Set(content.map(el => el.insuranceCarrierId))];
      const carriers = await this.getCarrierByIds(movementInsuranceCarrierIds);

      for (const movement of content) {
        if (movement.beneficiaryType === "HOLDER") {
          if (['SUBMITTED'].includes(movement.status)) {

            const movementCarrier = carriers.find(
              el => el.id === movement.insuranceCarrierId
            );

            movement.movementCarrier = movementCarrier;
          }
        }
      }

      this.movements = content;
      this.totalPages = totalElements;
    },
    verifyParameterNumber(parameter) {
      if (String(parameter)) {
        if (parameter <= 0) {
          return false;
        }
        return true;
      }
      return true;
    },
    verifyParameterName(parameter) {
      if (parameter) {
        if (this.rule.name(parameter) === true && this.rule.validateIsAlphanumeric(parameter) === true) {
          return true;
        }
        return false;
      }
      return true;
    },
    verifyHasSelected() {
      const hasSelected = this.movementSelectedsWithoutDependents && this.movementSelectedsWithoutDependents.length;
      if (!hasSelected) {
        this.messageTypeDialog = 'ACTION_BLOCK_TYPE_ERROR';
        this.showInfoDialog = true;
      }
      return hasSelected;
    },
    onClickFillCard() {
      if (!this.verifyHasSelected()) {
        return;
      }

      this.movementCustoms = this.movementCustoms.filter((item) => item.status && item.status === 'SUBMITTED');
      if (!this.movementCustoms.length) {
        this.messageTypeDialog = 'ACTION_BLOCK_TYPE_ERROR';
        this.actionBlockError = {
          message: 'Somente movimentações com status "Enviado para a Operadora" podem ter o cartão editado.\t\n'
            + 'Alguns benefícios também podem não permitir o preenchimento de cartão.',
          title: 'Ops... nenhum registro elegível para preenchimento do cartão',
          align: 'justify',
        };
        this.showInfoDialog = true;
        return;
      }

      this.openCardDialog();
    },
    onClickFillCardFinish() {
      if (!this.verifyHasSelected()) {
        return;
      }
      const validStatus = ['SUBMITTED', 'WAITING_APPROVAL_RULES_VALIDATIONS', 'SCHEDULED', 'VALID', 'IN_REVIEW', 'INVALID', 'INVALID_IN_CARRIER'];
      this.movementCustoms = this.movementCustoms.filter((item) => item.status && validStatus.includes(item.status));
      if (!this.movementCustoms.length) {
        this.messageTypeDialog = 'ACTION_BLOCK_TYPE_ERROR';
        this.actionBlockError = {
          message: 'Não é possível finalizar a movimentação no status atual.',
          title: 'Ops... nenhum registro elegível para finalização',
          align: 'justify',
        };
        this.showInfoDialog = true;
        return;
      }

      this.processMovementsInBlockToFinish();

      this.openCardDialogFinishInBlock();
    },
    processMovementsInBlockToFinish() {
      let movementsToFinish = [];

      this.movementCustoms.forEach(mov => {
        if (mov.beneficiaryType === "HOLDER") {
          movementsToFinish.push(mov);
          const dependents = mov.dependents;
          if (Boolean(dependents)) {
            movementsToFinish = [...movementsToFinish, ...dependents];
          }
        } else {
          movementsToFinish.push(mov);
        }
      })

      this.movementCustoms = movementsToFinish;
    },
    onClickReleaseMovement() {
      if (!this.verifyHasSelected()) {
        return;
      }

      this.movementReleaseItems = this.movementSelectedsWithoutDependents.filter((item) => item.status
        && item.status === 'WAITING_APPROVAL_RULES_VALIDATIONS');
      if (!this.movementReleaseItems.length) {
        this.messageTypeDialog = 'ACTION_BLOCK_TYPE_ERROR';
        this.actionBlockError = {
          message: 'Só podem ser liberados registros no status "Aguardando liberação".',
          title: 'Ops... nenhum registro elegível para liberação',
          align: 'center',
        };
        this.showInfoDialog = true;
        return;
      }

      this.totalItensDialog = this.movementSelectedsWithoutDependents.length;
      this.messageTypeConfirmDialog = 'RELEASE_TYPE_ACTION';
      this.showConfirmDialog = true;
    },
    onClickRevertRelease() {
      if (!this.verifyHasSelected()) {
        return;
      }

      this.movementRevertItems = this.movementSelectedsWithoutDependents.filter((item) => (item.status === 'VALID' || item.status === 'ADJUSTED') && !item.movementInsuranceCarrierId);
      if (!this.movementRevertItems.length) {
        this.messageTypeDialog = 'ACTION_BLOCK_TYPE_ERROR';
        this.actionBlockError = {
          message: 'Só podem ser revertidas as liberações dos registros que estejam no status "Liberado" '
            + 'e que não estejam em processo de fechamento do lote de envio para a operadora.',
          title: 'Ops... nenhum registro elegível para reversão de liberação',
          align: 'justify',
        };
        this.showInfoDialog = true;
        return;
      }

      this.totalItensDialog = this.movementSelectedsWithoutDependents.length;
      this.messageTypeConfirmDialog = 'REVERT_TYPE_ACTION';
      this.showConfirmDialog = true;
    },
    onClickCancelMovementBlock() {
      if (this.movementSelecteds.length > 0) {
        this.$refs.CancelMovementBlockModal.open(this.allMovementsSelected);
      } else {
        this.setSnackbarCustomize('error', 'Selecione ao menos uma movimentação.');
      }
    },
    closeConfirmModal() {
      this.showConfirmDialog = false;
      this.loadingConfirmDialog = false;
    },
    selectAllPossibleMovements() {
      this.movementSelecteds = [];
      this.movements.forEach((movementHolder) => {
        if (movementHolder) {
          this.movementSelecteds.push(movementHolder.id);
        }
      });
      this.allMovementsSelected = this.allMovementIds;
      this.isMultSelect = true;
      this.isSelectedAllMovements = false;
      this.allPossibleMovementsSelected = true;
    },
    cancelSelection() {
      this.allMovementsSelected = [];
      this.allMovementIds = [];
      this.movementSelecteds = [];
      this.isMultSelect = false;
      this.isSelectedAllMovements = false;
      this.allPossibleMovementsSelected = false;
      this.showSelectedMovements = false;
    },
    async confirmActionModal(messageType) {
      if (!this.hasAuthorityReleaseMovement) {
        return;
      }

      store.isCustomDialog = true;
      this.loadingConfirmDialog = true;
      const movementRecordService = new MovementRecordService();

      if (messageType && messageType === 'REVERT_TYPE_ACTION') {
        const data = {
          ids: this.movementRevertItems.map((item) => item.id),
          status: 'WAITING_APPROVAL_RULES_VALIDATIONS',
        };

        await movementRecordService.UpdateStatusFromMultipleMovements(data).then((response) => {
          const updatedMovementIds = response.data;
          this.movementRevertItems = this.movementRevertItems.filter((item) => updatedMovementIds.includes(item.id));

          this.loadingConfirmDialog = false;
          this.showConfirmDialog = false;
          this.messageTypeDialog = 'ACTION_BLOCK_TYPE_SUCCESS';
          this.actionBlockSuccess.title = 'Liberações revertidas com sucesso';
          this.actionBlockSuccess.message = `${this.totalItensDialog} registros selecionados | ${updatedMovementIds.length} registros com liberação revertida`;
          this.showInfoDialog = true;
        }).catch(() => {
          this.loadingConfirmDialog = false;
          this.showConfirmDialog = false;
          this.showErrorMessageDialog();
        });
      } else if (messageType && messageType === 'RELEASE_TYPE_ACTION') {
        const data = {
          ids: this.movementReleaseItems.map((item) => item.id),
          status: 'VALID',
        };

        await movementRecordService.UpdateStatusFromMultipleMovements(data).then((response) => {
          const updatedMovementIds = response.data;
          this.movementReleaseItems = this.movementReleaseItems.filter((item) => updatedMovementIds.includes(item.id));

          this.loadingConfirmDialog = false;
          this.showConfirmDialog = false;
          this.messageTypeDialog = 'ACTION_BLOCK_TYPE_SUCCESS';
          this.actionBlockSuccess.title = 'Movimentações liberadas com sucesso';
          this.actionBlockSuccess.message = `${this.totalItensDialog} registros selecionados | ${updatedMovementIds.length} registros liberados`;
          this.showInfoDialog = true;
        }).catch(() => {
          this.loadingConfirmDialog = false;
          this.showConfirmDialog = false;
          this.showErrorMessageDialog();
        });
      }
      store.isCustomDialog = false;
    },
    showErrorMessageDialog() {
      this.messageTypeDialog = 'ACTION_BLOCK_TYPE_ERROR';
      this.actionBlockError = {
        message: 'Por favor tente novamente e, persistindo o erro acione o seu responsável de suporte.',
        title: 'Ops... algo deu errado',
        align: 'center',
      };
      this.showInfoDialog = true;
    },
    resetActionBlockError() {
      this.actionBlockError = {
        message: 'Por favor, selecione pelo menos um registro.',
        title: 'Ops... nenhum registro selecionado',
        align: 'center',
      };
    },
    resetActionBlockSuccess() {
      this.actionBlockSuccess = {
        message: '',
        title: '',
      };
    },
    clearControllParameters() {
      this.messageTypeConfirmDialog = 'REVERT_TYPE_ACTION';
      this.loadingConfirmDialog = false;
      this.showInfoDialog = false;
      this.showConfirmDialog = false;
      this.totalItensDialog = 0;
      this.movementSelecteds = [];
      this.movementCustoms = [];
      this.movementSelectedsWithoutDependents = [];
      this.movementRevertItems = [];
      this.movementReleaseItems = [];
      this.isMultSelect = false;
      this.isSelectedAllMovements = false;
      this.resetActionBlockError();
      this.resetActionBlockSuccess();
    },
    clearPageControll() {
      this.totalPages = 1;
      this.page = 1;
    },
    managerBlockActionItem(item) {
      const movementFound = this.movementSelectedsWithoutDependents.find((movement) => movement.id === item.id);
      if (movementFound) {
        const indexOf = this.movementSelectedsWithoutDependents.indexOf(movementFound);
        if (indexOf >= 0) {
          this.movementSelectedsWithoutDependents.splice(indexOf, 1);
        }
      } else {
        this.movementSelectedsWithoutDependents.push(item);
      }
    },
    verifyBlockActionIsAllSelected() {
      if (this.movementSelectedsWithoutDependents.length !== this.movements.length) {
        this.isMultSelect = false;
        this.isSelectedAllMovements = false;
      }
    },
    applyForBlockAction(item) {
      this.applyForCard(item, 'efetivation');
      this.managerBlockActionItem(item);
      this.verifyBlockActionIsAllSelected();
      this.allMovementsSelected = this.movementSelecteds;
      this.findAllIds();
    },
    handlerFilter(value, type) {
      if (type && (type === 'click' || type === 'isPendingMovements')) {
        this.page = 1;
      }

      const filteredValue = type === 'click' ? { ...value, pendingMovements: undefined } : value;

      this.filtersMovement = cloneDeep(filteredValue);
      this.getMovements();
    },
    async changePage(event) {
      if (!this.createdComponent && this.page !== event.page) {
        this.handlePageChange(event);
      } else if (this.createdComponent) {
        if (sessionStorage.getItem('searchParams')) {
          this.handleSearchPageChange();
        }
      } else {
        this.handleItemsPerPageChange(event);
      }
    },
    async handlePageChange(event) {
      const { page: newPage, itemsPerPage } = event;
      this.page = newPage;
      this.itemsPerPage = itemsPerPage;
      await this.getMovements();
    },
    async handleSearchPageChange() {
      this.filtersMovement = await cloneDeep(JSON.parse(sessionStorage.getItem('onlyParameterFilter')));
      const { page: newPage, size: itemsPerPage } = JSON.parse(sessionStorage.getItem('searchParams'));
      this.page = Number(newPage);
      this.itemsPerPage = itemsPerPage;
      await this.getMovements();
    },
    async handleItemsPerPageChange(event) {
      const { page: newPage, itemsPerPage } = event;
      this.page = newPage;
      this.itemsPerPage = itemsPerPage;
      await this.getMovements();
    },
    verifyCountDocuments(event) {
      this.countUploadDocuments = event;
    },
    async loadMovementDataByMovementRecord(movementRecord) {
      await this.loadDependentsByMovementRecord(movementRecord);

      await this.countDocumentsByMovementRecordId(movementRecord.id);
    },
    async countDocumentsByMovementRecordId(movementRecordId) {
      if ((movementRecordId && this.filterDocuments && (this.filterDocuments.movementRecordId === null || this.filterDocuments.movementRecordId !== movementRecordId)) || (this.countUploadDocuments !== this.filterDocuments.countDocuments)) {
        this.loadingCountDocuments = true;
        this.filterDocuments.movementRecordId = null;
        this.filterDocuments.countDocuments = 0;

        const queryString = `?movementRecordId=${movementRecordId}`;
        await this.documentService.CountDocumentByFilters(queryString).then((response) => {
          this.filterDocuments.movementRecordId = movementRecordId;
          if (response) {
            this.filterDocuments.countDocuments = response.data;
            this.countUploadDocuments = response.data;
          }
        }).catch(() => {
          this.loadingCountDocuments = false;
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao buscar os documentos');
        }).finally(() => {
          this.loadingCountDocuments = false;
        });
      }
    },
    async openModalToAttachDocuments(item) {
      if (this.filterDocuments && this.filterDocuments.movementRecordId) {
        this.filterDocuments.protocolNumber = item.fileDataId;
        this.setOverlay(true);
        const queryString = `?movementRecordId=${this.filterDocuments.movementRecordId}`;
        await this.documentService.FindDocumentByFilters(queryString).then((response) => {
          if (response && response.data) {
            this.filterDocuments.documents = response.data;
            this.$refs.UploadMovementsModal.open(this.filterDocuments, 'MOVEMENT');
          }
        }).catch(() => {
          this.setOverlay(false);
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao buscar os documentos');
        }).finally(() => {
          this.setOverlay(false);
        });
      }
    },
    verifyMovementType() {
      switch (this.movementRecordProps.movementType) {
        case 'INSERT':
        case 'INSERT_DEPENDENT':
          this.adjustedForMovementInsert();
          break;
        case 'UPDATE':
        case 'UPDATE_DEPENDENT':
          this.adjustedForMovementUpdate();
          break;
        case 'DELETE':
        case 'DELETE_DEPENDENT':
          this.adjustedForMovementDelete();
          break;

        default:
          break;
      }
    },
    closeExclusionDynamicModal() {
      this.exclusionDynamicModal.show = false;
    },
    openExclusionDynamicModal(item) {
      this.movementRecordParams = item;
      this.$refs.CancelMovementBlockModal.open([item.id]);
      // this.exclusionDynamicModal.show = true;
    },
    confirmMovementDelete() {
      if (this.hasAuthorityCancelMovement) {
        if (this.validationConditionForMovementCancel()) {
          this.processMovementRecordService(this.movementRecordService.UpdateStatus, this.movementRecordParams.id, 'CANCELED');
        } else {
          this.setSnackbarCustomize('error', 'Operação não permitida!');
        }
      } else {
        this.setSnackbarCustomize('error', 'Você não tem permissão para liberar a movimentação');
      }
      this.closeExclusionDynamicModal();
    },
    validationConditionForMovementCancel() {
      if (((this.movementRecordParams.status !== 'CANCELED' || this.movementRecordParams.status !== 'SUBMITTED' || this.movementRecordParams.status !== 'SUCCESS') && this.movementRecordParams.movementInsuranceCarrierId === null) || (this.movementRecordParams.status === 'INVALID_IN_CARRIER' && this.movementRecordParams.movementInsuranceCarrierId !== null)) {
        return true;
      }
      return false;
    },
    async processMovementRecordService(serviceMethod, ...args) {
      try {
        await serviceMethod.call(this.movementRecordService, ...args);
        this.selectPagination();
        this.$forceUpdate();
        this.setSnackbarCustomize('success', 'Exclusão realizada com sucesso');
      } catch (error) {
        this.$forceUpdate();
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao cancelar a movimentação');
      }
    },
    onClickViewLogs(id) {
      this.setQueryForRedirection();
      this.redirectRouter('HistoryLog', { isRHProtegido: this.isRHProtegido }, { type: 'movement', id });
    },
    resetParamsFilter() {
      this.filtersMovement.startDate = '';
      this.filtersMovement.endDate = '';
      this.filtersMovement.dateType = [];
      this.filtersMovement.reportLayout = "REPORT_MOVEMENT_RECORD_DEFAULT";
    },
    formatDateAndTime(date) {
      const formattedDateTime = this.formatter.formatDateTimeZoneWithHours(date);
      const [formattedDate, formattedTime] = formattedDateTime.split(' ');
      return { date: formattedDate, time: formattedTime };
    },
    setQueryForRedirection() {
      const validDateKeys = ['isMovementDate', 'isCarrierSendDate', 'isCarrierFinishedDate', 'isValidityDate', 'isSendDate'];
      const dateType = validDateKeys.filter((key) => this.queryParams[key]);
      const selectedDate = sessionStorage.getItem('selectedDate');

      const queryParams = {
        ...this.queryParams,
        page: this.page,
        tab: 'movements',
        dateType,
        selectedDate,
      };

      sessionStorage.setItem('searchParams', JSON.stringify(queryParams));
      sessionStorage.setItem('onlyParameterFilter', JSON.stringify(this.onlyParameterFilter));
    },
    hasDependentWithError() {
      this.expandedDependents = [];

      if (this.movements && this.movements.length > 0) {
        this.movements.forEach((movement) => {
          if (movement.dependents && movement.dependents.length > 0) {
            if (movement.dependents.some((dependent) => dependent.hasError)) {
              this.expandedDependents.push(movement);
            }
          }
        });
      }
    },
    setOverlay(value) {
      this.overlay = value;
    },
    setSnackbarCustomize(type, message) {
      this.$refs.SnackbarCustomize.open(type, message);
    },
    async loadBenetiType() {
      this.benefitService.FindAll().then((response) => {
        if (response && response.data && response.data.records.length) {
          this.benefitTypes = response.data.records.filter((benefit) => benefit.xipp_contract_record_type_code !== null);
        }
      });
    },
    formatBenefitType(benefitType) {
      let benefitName = '-';
      if (this.benefitTypes && this.benefitTypes.length > 0) {
        const benefitFound = this.benefitTypes.find((benefit) => benefit.xipp_contract_record_type_code === benefitType);
        benefitName = benefitFound && benefitFound.name ? benefitFound.name : '-';
      }
      return benefitName;
    },
    benefitData(item) {
      const { policy = '-', subContract = '-', benefitType } = item || {};
      const formattedBenefitType = benefitType ? `${this.formatBenefitType(benefitType)}` : '-';

      const policyValue = policy !== '-' ? `${policy} / ` : '- ';
      const subContractValue = subContract !== '-' ? `${subContract}` : '-';
      const formattedBenefitTypeValue = formattedBenefitType !== '-' ? `${formattedBenefitType} - ` : '';

      return `${formattedBenefitTypeValue}${policyValue}${subContractValue}`;
    },
    async loadDependents({item, value} ) {
      if (!value) return;

      if (item.dependents && item.dependents.length > 0) return;

      this.indexInLoading = null;

      this.indexInLoading = this.movements.findIndex(
        el => el.id === item.id);

      this.loadingDependents = true;

      item.dependents = [];
      
      await this.movementRecordService.FindDependentsByFamilyGroupId(item.familyGroupId).then((response) => {

        if (response && response.data) {
          item.dependents = response.data;
          this.loadingMovements = false;
        }

      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Erro ao carregar os dependentes');
      }).finally(() => {
        this.indexInLoading = null;
        this.loadingDependents = false;
      });
    },
    async loadDependentsByMovementRecord(item) {
      if ((item.dependents && item.dependents.length > 0) || item.dependentsSize === 0) return;

      item.dependents = [];
      
      await this.movementRecordService.FindDependentsByFamilyGroupId(item.familyGroupId).then((response) => {

        if (response && response.data) {
          item.dependents = response.data;
        }

      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Erro ao carregar os dependentes');
      });
    }
  },
};
</script>

<style scoped>
.custom-tooltip {
  position: relative;
}

.v-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #3B495B;
  bottom: -15%;
  left:45%;
}
</style>
<style>
.statusMovementColumn {
  width: 200px;
}
</style>
